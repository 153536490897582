import {
  IconDefinition,
  faClipboardList,
  faFileContract,
  faList,
  faPlusSquare,
  faShippingFast,
} from '@fortawesome/free-solid-svg-icons';
import { TFunction } from 'i18next';

export type MenuItem = {
  label: string;
  icon: IconDefinition;
  path: string;
  additionalRoutes?: string[];
};

export function getLinks(t: TFunction): MenuItem[] {
  return [
    {
      label: t('Create an Order'),
      icon: faPlusSquare,
      path: '/orders/create',
    },
    {
      label: t('My Orders'),
      icon: faShippingFast,
      path: '/orders',
      additionalRoutes: [
        '/orders/:serialNo/track',
        '/orders/:id/view',
        '/orders/:id/edit',
        '/orders/:id/edit',
        '/orders/:id/recreate',
        '/orders/delete',
      ],
    },
    {
      label: t('Batch Orders'),
      icon: faClipboardList,
      path: '/orders/batch',
      additionalRoutes: [
        '/orders/batch/:id/orders',
        '/orders/batch/:id/log',
        '/orders/batch/web-import',
        '/orders/batch/file-import',
      ],
    },
    {
      label: t('Batch Order Templates'),
      icon: faList,
      path: '/orders/batch-order-templates',
    },
    {
      label: t('Warrants'),
      icon: faFileContract,
      path: '/warrants',
      additionalRoutes: ['/warrants/:id/parcels'],
    },
    {
      label: t('Delivery attempts'),
      icon: faShippingFast,
      path: '/delivery-attempts',
      additionalRoutes: ['/delivery-attempts/:id/parcels'],
    },
  ];
}
