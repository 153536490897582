import { faMinus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dispatch, useCallback, useMemo } from 'react';
import { useState } from 'react';
import { SetStateAction } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-lodash-debounce';

import useHaveValuesChanged from '../../../hooks/useHaveValuesChanged';
import useSearchQueryDateParam from '../../../hooks/useSearchQueryDateParam';
import useSearchQueryDropdownParam from '../../../hooks/useSearchQueryDropdownParam';
import useSearchQueryParam from '../../../hooks/useSearchQueryParam';
import { debounceTimeout } from '../../../utils/constants/misc';
import { httpDateFormat } from '../../../utils/helpers/formatting';
import { renderIcon } from '../../../utils/helpers/icon';
import {
  getSearchQueryParam,
  tryDateSearchParam,
} from '../../../utils/helpers/searchQuery';

function useTableFilters(
  page: number,
  setPage: Dispatch<SetStateAction<number>>,
  limit: number
) {
  const { t } = useTranslation();

  const location = useLocation();
  const [operation, setOperation] = useState(
    () => getSearchQueryParam(location.search, 'operation') ?? ''
  );
  const [dateFrom, setDateFrom] = useState<Date | null>(
    () =>
      tryDateSearchParam(getSearchQueryParam(location.search, 'date') ?? '') ??
      new Date()
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    () =>
      tryDateSearchParam(getSearchQueryParam(location.search, 'date') ?? '') ??
      new Date()
  );
  const [courierName, setCourierName] = useState(
    () => getSearchQueryParam(location.search, 'courier_name') ?? ''
  );
  const [customerName, setCustomerName] = useState(
    () => getSearchQueryParam(location.search, 'customer_name') ?? ''
  );
  const [reasonName, setReasonName] = useState(
    () => getSearchQueryParam(location.search, 'reason_name') ?? ''
  );
  const [comment, setComment] = useState(
    () => getSearchQueryParam(location.search, 'comment') ?? ''
  );

  const debouncedNameCourierName = useDebounce(courierName, debounceTimeout);
  const debouncedCustomerName = useDebounce(customerName, debounceTimeout);
  const debouncedReasonName = useDebounce(reasonName, debounceTimeout);
  const debouncedComment = useDebounce(comment, debounceTimeout);

  const operationsOptions = useMemo(
    () => [
      { label: t('Receive'), value: 1 },
      { label: t('Delivery'), value: 2 },
    ],
    [t]
  );

  const filtersArr = useMemo(
    () => [
      dateFrom,
      dateTo,
      operation,
      courierName,
      customerName,
      reasonName,
      comment,
    ],
    [
      customerName,
      dateFrom,
      dateTo,
      operation,
      comment,
      reasonName,
      courierName,
    ]
  );

  const haveFiltersChanged = useHaveValuesChanged(filtersArr);

  useEffect(() => {
    setPage(1);
  }, [
    setPage,
    dateFrom,
    dateTo,
    operation,
    debouncedNameCourierName,
    debouncedCustomerName,
    debouncedReasonName,
    debouncedComment,
  ]);

  useSearchQueryDateParam('date_from', dateFrom);
  useSearchQueryDateParam('date_to', dateTo);
  useSearchQueryParam('operation', operation);
  useSearchQueryParam('courier_name', debouncedNameCourierName);
  useSearchQueryParam('customer_name', debouncedCustomerName);
  useSearchQueryParam('reason_name', debouncedReasonName);
  useSearchQueryParam('comment', debouncedComment);
  useSearchQueryDropdownParam(
    'operation',
    operation,
    setOperation,
    operationsOptions
  );

  const httpFiltersObj = useMemo(
    () => ({
      date_from: dateFrom ? httpDateFormat(dateFrom) : undefined,
      date_to: dateTo ? httpDateFormat(dateTo) : undefined,
      operation: operation,
      courier_name: debouncedNameCourierName,
      customer_name: debouncedCustomerName,
      reason_name: debouncedReasonName,
      comment: debouncedComment,
      page: haveFiltersChanged ? 1 : page,
      limit,
    }),
    [
      dateFrom,
      dateTo,
      operation,
      debouncedNameCourierName,
      debouncedCustomerName,
      haveFiltersChanged,
      limit,
      debouncedReasonName,
      debouncedComment,
      page,
    ]
  );

  const basicFiltersActiveFilterCount = Object.values({
    dateFrom,
    operation,
    courierName,
    customerName,
    reasonName,
    comment,
  }).filter(Boolean).length;

  const basicFiltersHeaderTemplate = useCallback(
    () => (
      <div className="accordionHeaderTemplate">
        <span>{t('Basic filters')}</span>
        {basicFiltersActiveFilterCount > 0 && (
          <span className="badge">{basicFiltersActiveFilterCount}</span>
        )}
      </div>
    ),
    [t, basicFiltersActiveFilterCount]
  );

  const filters = useMemo<JSX.Element>(
    () => (
      <Accordion multiple activeIndex={[0]}>
        <AccordionTab disabled headerTemplate={basicFiltersHeaderTemplate}>
          <div className="sidebar_filter">
            <label htmlFor="filter_date_from">{t('Date')}</label>
            <div className="sidebar_filter_row">
              <Calendar
                value={dateFrom ?? undefined}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                inputId="bulk-orders-filter_date_from"
                className={classNames({
                  sidebar_filter_active: dateFrom,
                })}
                onChange={(e) => {
                  setDateFrom(e.value as Date | null);
                }}
              />
              <span style={{ margin: 'auto' }}>{renderIcon(faMinus)}</span>
              <Calendar
                value={dateTo ?? undefined}
                monthNavigator
                yearNavigator
                yearRange={`2009:${dayjs().format('YYYY')}`}
                dateFormat="dd/mm/yy"
                maxDate={new Date()}
                inputId="bulk-orders-filter_date_to"
                className={classNames({
                  sidebar_filter_active: dateTo,
                })}
                onChange={(e) => {
                  setDateTo(e.value as Date | null);
                }}
              />
            </div>
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_operation">{t('Operation')}</label>
            <Dropdown
              name="filter_operation"
              id="filter_operation"
              options={operationsOptions}
              value={operation}
              showClear
              className={classNames({
                sidebar_filter_active: operation,
              })}
              onChange={(e) => setOperation(e.value)}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_courier_name">{t('Courier Name')}</label>
            <InputText
              id="filter_courier_name"
              value={courierName}
              className={classNames({
                sidebar_filter_active: courierName,
              })}
              onChange={(e) => setCourierName(e.target.value)}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_customer_name">{t('Customer Name')}</label>
            <InputText
              id="filter_customer_name"
              value={customerName}
              className={classNames({
                sidebar_filter_active: customerName,
              })}
              onChange={(e) => setCustomerName(e.target.value)}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_reason_name">{t('Reason Name')}</label>
            <InputText
              id="filter_reason_name"
              value={reasonName}
              className={classNames({
                sidebar_filter_active: reasonName,
              })}
              onChange={(e) => setReasonName(e.target.value)}
            />
          </div>
          <div className="sidebar_filter">
            <label htmlFor="filter_barcode">{t('Comment')}</label>
            <InputText
              id="filter_barcode"
              value={comment}
              className={classNames({
                sidebar_filter_active: comment,
              })}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </AccordionTab>
      </Accordion>
    ),
    [
      dateFrom,
      dateTo,
      operation,
      operationsOptions,
      courierName,
      customerName,
      reasonName,
      comment,
      t,
      basicFiltersHeaderTemplate,
    ]
  );

  function resetAllFilters() {
    setDateFrom(new Date());
    setDateTo(new Date());
    setOperation('');
    setCourierName('');
    setCustomerName('');
    setReasonName('');
    setComment('');
  }

  const headerFiltersCount = useMemo(
    () => basicFiltersActiveFilterCount,
    [basicFiltersActiveFilterCount]
  );

  return {
    filters,
    resetAllFilters,
    httpFiltersObj,
    headerFiltersCount,
  };
}

export default useTableFilters;
