import { Dispatch, SetStateAction, useEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';

import Page401 from '../ErrorPages/Page401';
import Page403 from '../ErrorPages/Page403';
import Page404 from '../ErrorPages/Page404';
import Page500 from '../ErrorPages/Page500';
import ForgotPassword from '../Pages/Authentication/ForgotPassword/ForgotPassword';
import ForgotPasswordSuccess from '../Pages/Authentication/ForgotPassword/pages/Success/ForgotPasswordSuccess';
import Login from '../Pages/Authentication/Login/Login';
import Logout from '../Pages/Authentication/Logout/Logout';
import Register from '../Pages/Authentication/Register/Register';
import ResetPasswordCodeExpired from '../Pages/Authentication/ResetPassword/pages/CodeExpired/ResetPasswordCodeExpired';
import ResetPasswordSuccess from '../Pages/Authentication/ResetPassword/pages/Success/ResetPasswordSuccess';
import ResetPassword from '../Pages/Authentication/ResetPassword/ResetPassword';
import UserActivate from '../Pages/Authentication/UserActivate/UserActivate';
import BulkOrders from '../Pages/BulkOrders/BulkOrders';
import FileImporter from '../Pages/BulkOrders/Importers/FileImporter/FileImporter';
import WebImporter from '../Pages/BulkOrders/Importers/WebImporter/WebImporter';
import BulkOrderTemplates from '../Pages/BulkOrderTemplates/BulkOrderTemplates';
import DeliveryAttempts from '../Pages/DeliveryAttempts/DeliveryAttempts';
import EmailConfiguration from '../Pages/EmailConfiguration/EmailConfiguration';
import Notifications from '../Pages/Notifications/Notifications';
import CreateEditRecreate from '../Pages/Orders/CreateEditRecreate/CreateEditRecreate';
import Orders from '../Pages/Orders/Orders';
import Profile from '../Pages/Profile/Profile';
import QRCodeGenerate from '../Pages/QRCode/QRCode';
import ViewOrders from '../Pages/Warrants/View/ViewOrders';
import Warrants from '../Pages/Warrants/Warrants';
import Welcome from '../Pages/Welcome/Welcome';
import AuthRoute from './AuthRoute';
import SentryRoute from './SentryRoute';
import UnauthRoute from './UnauthRoute';

type Props = {
  setRouterLocation: Dispatch<SetStateAction<string | null>>;
};

function Routes({ setRouterLocation }: Props): JSX.Element {
  const location = useLocation();

  useEffect(() => {
    setRouterLocation(location.pathname);
  }, [location.pathname, setRouterLocation]);

  return (
    <Switch>
      {/* These are intentionally left as public routes, as per requirements */}
      <SentryRoute path="/profile/activate/:code" component={UserActivate} />
      <SentryRoute
        path="/profile/password-reset/success"
        component={ResetPasswordSuccess}
      />
      <SentryRoute
        path="/profile/password-reset/expired"
        component={ResetPasswordCodeExpired}
      />
      <SentryRoute
        path="/profile/password-reset/:code"
        component={ResetPassword}
      />
      <SentryRoute
        path="/profile/forgot-password/success"
        component={ForgotPasswordSuccess}
      />
      <SentryRoute path="/profile/forgot-password" component={ForgotPassword} />

      <UnauthRoute path="/login" component={Login} />
      <UnauthRoute path="/register" component={Register} />
      <AuthRoute path="/logout" component={Logout} />

      <AuthRoute path="/welcome" component={Welcome} />
      <AuthRoute path="/profile" component={Profile} />
      <AuthRoute path="/email-configuration" component={EmailConfiguration} />
      <AuthRoute path="/notifications" component={Notifications} />
      <AuthRoute path="/qr-code" component={QRCodeGenerate} />

      <AuthRoute path="/orders/batch/file-import" component={FileImporter} />
      <AuthRoute path="/orders/batch/web-import" component={WebImporter} />
      <AuthRoute
        path={[
          '/orders/batch/:id/orders',
          '/orders/batch/:id/log',
          '/orders/batch',
        ]}
        component={BulkOrders}
      />

      <AuthRoute
        path="/orders/batch-order-templates"
        component={BulkOrderTemplates}
      />

      <AuthRoute
        path={['/orders/create', '/orders/:id/edit', '/orders/:id/recreate']}
        component={CreateEditRecreate}
      />

      <AuthRoute
        path={[
          '/orders/:serialNo/track',
          '/orders/:id/view',
          '/orders/delete',
          '/orders',
        ]}
        component={Orders}
      />

      <AuthRoute path="/warrants/:id/parcels" component={ViewOrders} />
      <AuthRoute path="/warrants" component={Warrants} />
      <AuthRoute
        path={['/delivery-attempts', '/delivery-attempts/:id/view']}
        component={DeliveryAttempts}
      />

      <SentryRoute path="/401" component={Page401} />
      <SentryRoute path="/403" component={Page403} />
      <SentryRoute path="/404" component={Page404} />
      <SentryRoute path="/500" component={Page500} />

      <SentryRoute path="/" exact>
        <Redirect to="/welcome" />
      </SentryRoute>

      <SentryRoute path="*">
        <Redirect to="/404" />
      </SentryRoute>
    </Switch>
  );
}

export default Routes;
