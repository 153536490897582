import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-lodash-debounce';

import useMediaQuery from '../../../../../../../hooks/useMediaQuery';
import { UnregisteredClientId } from '../../../../../../../types/api/orders';
import { LabelValue } from '../../../../../../../types/options';
import { ReduxState } from '../../../../../../../types/redux';
import { halfSecundDebounceTimeout } from '../../../../../../../utils/constants/misc';
import { invalidDecimalPointCharactersRegex } from '../../../../../../../utils/constants/regex';
import FieldWithErrors from '../../../../../../Forms/ReactHookForm/FieldWithErrors/FieldWithErrors';
import {
  CustomerRole,
  FormFields,
  RedemptionFieldValidation,
  RedemptionReceiver,
  RedemptionReceiverFieldValidation,
  getRedemptionReceiverTooltip,
  getRedemptionTooltip,
  validateRedemptionField,
  validateRedemptionReceiverField,
} from '../../../CreateEditRecreate.functions';
import StepsContext from '../../../StepsContext';
import InsuredAmount from '../Fields/InsuredAmount';
import PackagingServices from '../Fields/PackagingServices';
import Quantity from '../Fields/Quantity';
import ReferenceNo1 from '../Fields/ReferenceNo1';
import ReferenceNo2 from '../Fields/ReferenceNo2';
import RelatedOrder from '../Fields/RelatedOrder';
import ReturnDocument from '../Fields/ReturnDocument';
import TimeFrame from '../Fields/TimeFrame';
import styles from './OrderTypes.module.scss';

function Package(): JSX.Element {
  const { t } = useTranslation();
  const isOnMobile = useMediaQuery('(max-width: 768px)');
  const { setValue, getValues } = useFormContext<FormFields>();
  const {
    orderSettings: { specialProductOptions, returnDocumentOptionsData },
  } = useContext(StepsContext);

  const [redemption, setRedemption] = useState<string>(
    getValues('otkup') ?? '0.00'
  );

  const debouncedRedemption = useDebounce(
    redemption,
    halfSecundDebounceTimeout
  );

  useEffect(() => {
    setValue('otkup', debouncedRedemption); // to prevent calling calculator on key up
  }, [debouncedRedemption, setValue]);

  const clientId = useSelector<ReduxState, ReduxState['user']['client_id']>(
    (s) => s.user.client_id
  );

  const klient_od_id = useWatch<FormFields, 'klient_od_id'>({
    name: 'klient_od_id',
  });

  const klient_do_id = useWatch<FormFields, 'klient_do_id'>({
    name: 'klient_do_id',
  });

  const klient_od_ime = useWatch<FormFields, 'klient_od_ime'>({
    name: 'klient_od_ime',
  });

  const customer_role = useWatch<FormFields, 'customer_role'>({
    name: 'customer_role',
  });

  const vrednost = useWatch<FormFields, 'vrednost'>({
    name: 'vrednost',
  });

  const otkup = useWatch<FormFields, 'otkup'>({
    name: 'otkup',
  });

  const sirina = useWatch<FormFields, 'sirina'>({
    name: 'sirina',
  });

  const visina = useWatch<FormFields, 'visina'>({
    name: 'visina',
  });

  const dolzina = useWatch<FormFields, 'dolzina'>({
    name: 'dolzina',
  });

  const redemptionReceiverOptions = useMemo<
    LabelValue<RedemptionReceiver>[]
  >(() => {
    const senderSuffix =
      customer_role === CustomerRole.Sender
        ? ''
        : klient_od_ime
        ? `(${klient_od_ime})`
        : '';

    const ordererSuffix =
      customer_role === CustomerRole.Sender ? '' : t('(me)');

    // Trim, because both suffixes may end up being empty
    const senderLabel = (t('Sender') + ' ' + senderSuffix).trim();
    const ordererLabel = (t('Orderer') + ' ' + ordererSuffix).trim();

    return [
      { label: senderLabel, value: RedemptionReceiver.Sender },
      { label: ordererLabel, value: RedemptionReceiver.Orderer },
    ];
  }, [customer_role, klient_od_ime, t]);

  const redemptionFieldValidation = useMemo(
    () => validateRedemptionField(klient_od_id, vrednost),
    [klient_od_id, vrednost]
  );

  const redemptionTooltip = useMemo(
    () => getRedemptionTooltip(t, redemptionFieldValidation),
    [redemptionFieldValidation, t]
  );

  const redemptionReceiverFieldValidation = useMemo(
    () =>
      validateRedemptionReceiverField(
        klient_od_id,
        klient_do_id,
        otkup,
        clientId
      ),
    [clientId, klient_od_id, klient_do_id, otkup]
  );

  const isRedemptionDisabled =
    redemptionFieldValidation ===
      RedemptionFieldValidation.UnregisteredSender &&
    klient_do_id !== UnregisteredClientId.Recipient;

  const redemptionReceiverTooltip = useMemo(
    () => getRedemptionReceiverTooltip(t, redemptionReceiverFieldValidation),
    [redemptionReceiverFieldValidation, t]
  );

  const isRedemptionReceiverDisabled =
    redemptionReceiverFieldValidation !==
    RedemptionReceiverFieldValidation.Valid;

  const isAnyDimensionFilledOut =
    parseFloat(sirina) > 0 || parseFloat(visina) > 0 || parseFloat(dolzina) > 0;

  return (
    <div
      className={isOnMobile ? styles.twoColumnLayout : styles.fourColumnLayout}
    >
      <Tooltip
        target=".disabled-redemption-tooltip"
        position="left"
        style={isRedemptionDisabled ? {} : { display: 'none' }}
      />

      <Tooltip
        target=".disabled-redemption-receiver-tooltip"
        position="left"
        style={isRedemptionReceiverDisabled ? {} : { display: 'none' }}
      />

      <Quantity />
      <InsuredAmount />

      <FieldWithErrors name="tezina" label={t('Weight')}>
        <Controller
          name="tezina"
          render={({ field }) => (
            <InputText
              id="tezina"
              name="tezina"
              value={field.value}
              onChange={(e) => {
                setValue(
                  'tezina',
                  e.target.value.replace(invalidDecimalPointCharactersRegex, '')
                );
              }}
              className="data-cy-weight"
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="sirina" label={<>{t('Width')} (m)</>}>
        <Controller
          name="sirina"
          render={({ field }) => (
            <InputText
              id="sirina"
              name="sirina"
              value={field.value}
              onChange={(e) => {
                setValue(
                  'sirina',
                  e.target.value.replace(invalidDecimalPointCharactersRegex, '')
                );
              }}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="visina" label={<>{t('Height')} (m)</>}>
        <Controller
          name="visina"
          render={({ field }) => (
            <InputText
              id="visina"
              name="visina"
              value={field.value}
              onChange={(e) => {
                setValue(
                  'visina',
                  e.target.value.replace(invalidDecimalPointCharactersRegex, '')
                );
              }}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="dolzina" label={<>{t('Length')} (m)</>}>
        <Controller
          name="dolzina"
          render={({ field }) => (
            <InputText
              id="dolzina"
              name="dolzina"
              value={field.value}
              onChange={(e) => {
                setValue(
                  'dolzina',
                  e.target.value.replace(invalidDecimalPointCharactersRegex, '')
                );
              }}
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="volumen"
        label={
          <>
            {t('Volume')} (m<sup>3</sup>)
          </>
        }
      >
        <Controller
          name="volumen"
          render={({ field }) => (
            <InputText
              id="volumen"
              name="volumen"
              value={field.value}
              disabled={isAnyDimensionFilledOut}
              onChange={(e) => {
                setValue(
                  'volumen',
                  e.target.value.replace(invalidDecimalPointCharactersRegex, '')
                );
              }}
              className="data-cy-volume"
            />
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="otkup" label={t('Redemption')}>
        <Controller
          name="otkup"
          render={() => (
            <div
              data-pr-tooltip={redemptionTooltip}
              className="disabled-redemption-tooltip"
            >
              <InputText
                id="otkup"
                name="otkup"
                value={isRedemptionDisabled ? 0 : redemption}
                disabled={isRedemptionDisabled}
                onChange={(e) => {
                  setRedemption(
                    // 'otkup',
                    e.target.value.replace(
                      invalidDecimalPointCharactersRegex,
                      ''
                    )
                  );
                }}
                className="data-cy-redemption"
              />
            </div>
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors name="klient_otkup_id" label={t('Redemption receiver')}>
        <Controller
          name="klient_otkup_id"
          render={({ field }) => (
            <div
              className="disabled-redemption-receiver-tooltip"
              data-pr-tooltip={redemptionReceiverTooltip}
            >
              <Dropdown
                name="klient_otkup_id"
                inputId="klient_otkup_id"
                options={redemptionReceiverOptions}
                disabled={isRedemptionReceiverDisabled}
                placeholder={t('Not applicable')}
                value={
                  isRedemptionReceiverDisabled
                    ? redemptionReceiverFieldValidation ===
                      RedemptionReceiverFieldValidation.SameSenderAndOrderer
                      ? RedemptionReceiver.Sender
                      : redemptionReceiverFieldValidation ===
                        RedemptionReceiverFieldValidation.OrdererRedemption
                      ? RedemptionReceiver.Orderer
                      : null
                    : field.value
                }
                onChange={(e) => field.onChange(e.value)}
              />
            </div>
          )}
        />
      </FieldWithErrors>

      <FieldWithErrors
        name="specijaliziran_proizvod_id"
        label={t('Specialized product')}
      >
        <Controller
          name="specijaliziran_proizvod_id"
          render={({ field }) => (
            <div className="specialized-product">
              <Dropdown
                name="specijaliziran_proizvod_id"
                inputId="specijaliziran_proizvod_id"
                options={specialProductOptions}
                placeholder={t('Select')}
                value={field.value}
                showClear
                onChange={(e) => field.onChange(e.value)}
                className="data-cy-special_product"
              />
            </div>
          )}
        />
      </FieldWithErrors>
      <ReferenceNo1 type="package" />
      <ReferenceNo2 />
      <RelatedOrder />
      <TimeFrame />
      <PackagingServices />
      <ReturnDocument returnDocumentOptionsData={returnDocumentOptionsData} />
      {getValues()?.replacement_shipment !== 2 && (
        // 2 = povratna zamenska pratka. Pratka koja se zema od primatelot vo momentot na isporaka na zamenska pratka i se vraka nazad do isprakacot.
        // se setira 2 samo na kurirska app
        <FieldWithErrors
          name="replacement_shipment"
          label={t('Replacement shipment')}
          className="data-cy-replacement_shipment"
        >
          <Controller
            name="replacement_shipment"
            render={({ field }) => (
              <InputSwitch
                {...field}
                checked={Boolean(field.value)}
                inputId={field.name}
              />
            )}
          />
        </FieldWithErrors>
      )}
    </div>
  );
}

export default Package;
