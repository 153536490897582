import { ClientResource } from '../../../types/api/clients';

type SingleNotification = {
  isChecked: boolean;
  channel: string;
  actor: string;
  event: string;
};

type Notifications = {
  [key: string]: SingleNotification;
};
export type FormValues = {
  import_notification: boolean;
  cod_email_warrant: boolean;
  sms_gateway: string;
  notifications?: { [key: string]: any };
};

export function getInitialValues(data: ClientResource | undefined): FormValues {
  return {
    notifications: generateNotificationsObject(data),
    sms_gateway: data?.sms_gateway || '',
    import_notification: data?.import_notification === 1,
    cod_email_warrant: data?.cod_email_warrant === 1,
  };
}

function generateNotificationsObject(data: ClientResource | undefined) {
  if (!data?.notification) {
    return {};
  }

  let notifications: Notifications = {};

  for (let n of data?.notification) {
    if (!n.event || !n.actor || !n.channel) {
      continue;
    }

    const id = `${n.event}-${n.actor}-${n.channel}`;

    notifications[id] = {
      ...n,
      isChecked: true,
    };
  }

  return notifications;
}

export function editDialogApiData(values?: FormValues) {
  let notifications = [];

  for (let notification in values?.notifications) {
    if (!values?.notifications[notification].isChecked) {
      continue;
    }

    notifications.push({
      event: values?.notifications[notification].event,
      actor: values?.notifications[notification].actor,
      channel: values?.notifications[notification].channel,
    });
  }

  return {
    notification: notifications,
    import_notification: values?.import_notification,
    cod_email_warrant: values?.cod_email_warrant,
  };
}
