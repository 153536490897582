import './DeliveryAttempts.scss';

import { faShippingFast } from '@fortawesome/free-solid-svg-icons';
import { Column } from 'primereact/column';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAxios from '../../../hooks/useAxios';
import usePageTitle from '../../../hooks/usePageTitle';
import useRouteDialog from '../../../hooks/useRouteDialog';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableState from '../../../hooks/useTableState';
import { queryString } from '../../../utils/helpers/http';
import Filters from '../../Components/Filters/Filters';
import HeaderPages from '../../Components/HeaderPages/HeaderPages';
import Table from '../../DataTable/Table/Table';
import Flex from '../../Layout/flex/Flex';
import MainContent from '../../Layout/flex/MainContent';
import {
  DeliveryAttemptsItem,
  DeliveryAttemptsItemList,
  Row,
  additionalColumnProperties,
  getColumnHeadersMap,
  tableStorageKey,
} from './DeliveryAttempts.functions';
import { DeliveryAttemptsView } from './Dialogs/View/DeliveryAttemptsView';
import useTableFilters from './useTableFilters';

function DeliveryAttempts(): JSX.Element {
  const { t } = useTranslation();
  usePageTitle(t('Delivery attempts'));
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [action, setAction] = useState<string>('');
  const {
    tableRef,
    page,
    setPage,
    limit,
    setLimit,
    sortField,
    sortOrder,
    selection,
    setSortField,
    setSortOrder,
    setSelection,
  } = useTableState<Row>(tableStorageKey);

  const [contextMenuSelection, setContextMenuSelection] = useState<
    DeliveryAttemptsItem | undefined
  >(undefined);

  const { filters, headerFiltersCount, resetAllFilters, httpFiltersObj } =
    useTableFilters(page, setPage!, limit);

  const columnHeadersMap = useMemo(() => getColumnHeadersMap(t), [t]);

  const { selectedColumns, setSelectedColumns, columnOptions, columns } =
    useTableColumns(page, limit, columnHeadersMap, columnHeadersMap, (c) =>
      additionalColumnProperties(
        t,
        c as keyof typeof columnHeadersMap,
        setContextMenuSelection,
        setAction
      )
    );

  const finalColumns = useMemo<JSX.Element[]>(
    () => [
      ...columns,
      <Column
        key="action-column"
        header={t('Actions')}
        field="actions"
        frozen
        alignFrozen="right"
        {...additionalColumnProperties(
          t,
          'actions',
          setContextMenuSelection,
          setAction
        )}
      />,
    ],
    [columns, t, setContextMenuSelection, setAction]
  );

  const canLoadData = httpFiltersObj.date_from && httpFiltersObj.date_to;

  const { data, isLoading, reload, error } = useAxios<DeliveryAttemptsItemList>(
    '/deliverytrackings' + queryString(httpFiltersObj),
    { skipWhen: !canLoadData }
  );

  const {
    show: showDeliveryAttemptsDialog,
    hide: hideDeliveryAttemptsDialog,
    isVisible: isDeliveryAttemptsDialogVisible,
  } = useRouteDialog('/delivery-attempts', `${contextMenuSelection?.id}/view`);

  const handleDeliveryAttemptsViewClick = useCallback(() => {
    if (!contextMenuSelection) {
      return;
    }
    showDeliveryAttemptsDialog();
  }, [contextMenuSelection, showDeliveryAttemptsDialog]);

  useEffect(() => {
    if (action && contextMenuSelection) {
      if (action === 'view') {
        handleDeliveryAttemptsViewClick();
      }
      if (action === 'delete') {
        setIsDeleteDialogVisible(true);
      }
      setAction('');
    }
  }, [
    action,
    contextMenuSelection,
    handleDeliveryAttemptsViewClick,
    setAction,
  ]);

  return (
    <div className="page delivery-attempts-list-page">
      <HeaderPages
        title={t('Delivery attempts')}
        subtitle={t('Review and report after delivery attempt.')}
        icon={faShippingFast}
      />

      <DeliveryAttemptsView
        selectedRow={contextMenuSelection}
        visible={isDeliveryAttemptsDialogVisible}
        onHide={hideDeliveryAttemptsDialog}
        setContextMenuSelection={setContextMenuSelection}
      />

      {/* <DeleteDialog
        selectedList={contextMenuSelection}
        isShown={isDeleteDialogVisible}
        onHide={() => setIsDeleteDialogVisible(false)}
        reloadImportedLists={reload}
      /> */}

      <Flex direction="column">
        <Filters
          filters={filters}
          resetAllFilters={resetAllFilters}
          headerFiltersCount={headerFiltersCount}
          filterHeight={240}
        />
        <MainContent>
          <Table
            headerTitle=""
            columnOptions={columnOptions}
            columns={finalColumns}
            data={data}
            hasError={!!error}
            isLoading={isLoading}
            onHeaderFiltersResetAllBtnClick={resetAllFilters}
            rebuildTooltip
            ref={tableRef}
            reload={reload}
            filterHeight={240}
            isReloadDisabled={!canLoadData}
            rows={limit}
            selectedColumns={selectedColumns}
            selection={selection}
            selectionPageOnly
            setLimit={setLimit}
            setPage={setPage}
            setSelectedColumns={setSelectedColumns}
            setSelection={setSelection}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            sortField={sortField}
            sortOrder={sortOrder}
            storageString={tableStorageKey}
            displayActionColumn
            contextMenuSelection={contextMenuSelection}
            setContextMenuSelection={setContextMenuSelection}
          />
        </MainContent>
      </Flex>
    </div>
  );
}

export default DeliveryAttempts;
