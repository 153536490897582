import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ToastContext from '../../../../../../context/ToastContext';
import useAxios from '../../../../../../hooks/useAxios';
import usePrevious from '../../../../../../hooks/usePrevious';
import { ClientAddressCollection } from '../../../../../../types/api/clientAddress';
import { LabelValue } from '../../../../../../types/options';
import { isStreetFreeInputAllowed } from '../../../../../../utils/constants/misc';
import { successToast } from '../../../../../../utils/helpers/primereact';
import AutoCompleteInput from '../../../../../Forms/AutoCompleteInput/AutoCompleteInput';
import { CustomerRole, FormFields } from '../../CreateEditRecreate.functions';

function Contact({
  role,
  contactData,
  isOptionsLoading,
  reloadContactData,
}: {
  role: CustomerRole;
  isOptionsLoading: boolean;
  contactData: ClientAddressCollection | undefined;
  reloadContactData: () => void;
}) {
  const { t } = useTranslation();

  const { toastRef } = useContext(ToastContext);

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
  const [selectedContact, setSelectedContact] = useState<LabelValue>();

  const customer_role = useWatch<FormFields, 'customer_role'>({
    name: 'customer_role',
  });

  const klient_od_ime = useWatch<FormFields, 'klient_od_ime'>({
    name: 'klient_od_ime',
  });

  const klient_do_ime = useWatch<FormFields, 'klient_do_ime'>({
    name: 'klient_do_ime',
  });

  const isSender = role === CustomerRole.Sender;

  const klient_ime = isSender ? klient_od_ime : klient_do_ime;

  const { setValue } = useFormContext<FormFields>();

  const handleFilterChange = useCallback(
    (value) => {
      if (isSender) {
        setValue('klient_od_ime', value);
      } else {
        setValue('klient_do_ime', value);
      }
    },
    [isSender, setValue]
  );

  function handleIdChange(e: string | null) {
    const clientAddress = contactData?.data?.find((c) => String(c.id) === e);
    if (isSender) {
      setValue('sender_contact_id', e ?? '');

      setValue('mesto_od_id', clientAddress?.place_id ?? '');
      setValue('mesto_od_ime', clientAddress?.place_name ?? '');
      setValue('mobilen_od', clientAddress?.mobile_phone ?? '');
      setValue('telefon_od', clientAddress?.phone ?? '');
      setValue('ulica_od_id', clientAddress?.street_id ?? '');
      setValue('ulica_od_ime', clientAddress?.street_name ?? '');
      if (isStreetFreeInputAllowed) {
        setValue('adresa_od', clientAddress?.address ?? '');
      } else {
        setValue('broj_od', clientAddress?.street_number ?? '');
        setValue('vlez_od', clientAddress?.entrance ?? '');
        setValue('stan_od', clientAddress?.apartment ?? '');
      }
    } else {
      setValue('recipient_contact_id', e ?? '');

      setValue('mesto_do_id', clientAddress?.place_id ?? '');
      setValue('mesto_do_ime', clientAddress?.place_name ?? '');
      setValue('mobilen_do', clientAddress?.mobile_phone ?? '');
      setValue('telefon_do', clientAddress?.phone ?? '');
      setValue('ulica_do_id', clientAddress?.street_id ?? '');
      setValue('ulica_do_ime', clientAddress?.street_name ?? '');
      if (isStreetFreeInputAllowed) {
        setValue('adresa_do', clientAddress?.address ?? '');
      } else {
        setValue('broj_do', clientAddress?.street_number ?? '');
        setValue('vlez_do', clientAddress?.entrance ?? '');
        setValue('stan_do', clientAddress?.apartment ?? '');
      }
    }
  }

  const options: LabelValue[] = useMemo(
    () =>
      contactData?.data?.map((c) => {
        return { label: c.contact, value: String(c.id) };
      }) ?? [],
    [contactData?.data]
  );

  function listItemTemplate(option: any) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{option.label}</div>
        <Button
          onClick={() => {
            setSelectedContact(option);
            setIsDeleteDialogVisible(true);
          }}
          type="button"
          icon="fa fa-times"
          className="p-button-text"
          style={{ padding: 0 }}
        />
      </div>
    );
  }

  useEffect(() => {
    if (selectedContact) {
      setIsDeleteDialogVisible(true);
    }
  }, [selectedContact]);

  const resetSenderFields = useCallback(() => {
    setValue('klient_od_ime', '');
    setValue('mesto_od_id', '');
    setValue('mesto_od_ime', '');
    setValue('mobilen_od', '');
    setValue('telefon_od', '');
    setValue('ulica_od_id', '');
    setValue('ulica_od_ime', '');
    setValue('adresa_od', '');
    setValue('broj_od', '');
    setValue('vlez_od', '');
    setValue('stan_od', '');
  }, [setValue]);

  const resetRecipientFields = useCallback(() => {
    setValue('klient_do_ime', '');
    setValue('mesto_do_id', '');
    setValue('mesto_do_ime', '');
    setValue('mobilen_do', '');
    setValue('telefon_do', '');
    setValue('ulica_do_id', '');
    setValue('adresa_do', '');
    setValue('broj_do', '');
    setValue('vlez_do', '');
    setValue('stan_do', '');
  }, [setValue]);

  const { reload: deleteContact, data: deleteContactData } = useAxios(
    {
      method: 'DELETE',
    },
    { skipWhen: true }
  );

  const previousDeleteContactData = usePrevious(deleteContactData);

  useEffect(() => {
    if (!deleteContactData || deleteContactData === previousDeleteContactData) {
      return;
    }
    if (isSender) {
      resetSenderFields();
    } else {
      resetRecipientFields();
    }

    reloadContactData();
    setIsDeleteDialogVisible(false);
    successToast(toastRef, t('Success'), t('Successfully deleted contact'));
  }, [
    deleteContactData,
    isSender,
    previousDeleteContactData,
    reloadContactData,
    resetRecipientFields,
    resetSenderFields,
    setValue,
    t,
    toastRef,
  ]);

  return (
    <>
      <Dialog
        onHide={() => setIsDeleteDialogVisible(false)}
        visible={isDeleteDialogVisible}
        footer={
          <Button
            label={t('Delete contact')}
            onClick={() =>
              deleteContact({
                url: `/client-addresses/${selectedContact?.value}`,
              })
            }
            type="button"
          />
        }
      >
        {t('Are you sure you want to delete contact {{contactName}}', {
          contactName: selectedContact?.label,
        })}
      </Dialog>

      <Controller
        name={isSender ? 'sender_contact_id' : 'recipient_contact_id'}
        render={({ field }) => (
          <AutoCompleteInput
            filterValue={klient_ime}
            value={field.value}
            onFilterChange={handleFilterChange}
            onSelectionChange={handleIdChange}
            itemTemplate={listItemTemplate}
            disabled={
              isSender
                ? customer_role === CustomerRole.Sender
                : customer_role === CustomerRole.Recipient
            }
            options={options}
            isLoading={isOptionsLoading}
            forceSelection={false}
            filterDataCy={isSender ? 'sender_name' : 'recipient_name'}
            hideEmptyOptions
          />
        )}
      />
    </>
  );
}

export default Contact;
