import classNames from 'classnames';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ListBox, ListBoxItemTemplateType } from 'primereact/listbox';
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { LabelValue } from '../../../types/options';
import { XOR } from '../../../types/util';
import { escapeRegex } from '../../../utils/helpers/regex';
import { emptyItemTemplate, findOption } from './AutoCompleteInput.functions';
import styles from './AutoCompleteInput.module.scss';

type Props<T> = {
  filterValue: string;
  value: T | null;
  options: LabelValue<T | null>[];
  onFilterChange: (filter: string) => void;
  onSelectionChange: (newValue: T | null) => void;
  forceSelection?: boolean;
  isLoading?: boolean;
  maxWidth?: number | false;
  maxHeight?: number;
  disableUnselect?: boolean;
  id?: string;
  name?: string;
  placeholder?: string;
  label?: string | false;
  disabled?: boolean;
  filterDataCy?: string;
  optionsClassName?: string;
  itemTemplate?: ListBoxItemTemplateType;
  valueTemplate?: (option: any) => string;
  showClear?: boolean;
  hideEmptyOptions?: boolean;
} & XOR<
  {
    lazy?: false;
    maxNonLazyItems?: number;
  },
  {
    lazy?: true;
    maxLazyItems?: number;
  }
>;

function AutoCompleteInput<T>({
  filterValue,
  value,
  options: _options,
  onFilterChange,
  onSelectionChange,
  forceSelection = true,
  isLoading = false,
  maxWidth = false,
  maxHeight = 300,
  disableUnselect = true,
  id,
  name,
  placeholder,
  label = false,
  disabled = false,
  filterDataCy,
  optionsClassName,
  itemTemplate,
  valueTemplate,
  showClear,
  hideEmptyOptions,
  lazy = true,
  maxNonLazyItems = 25,
  maxLazyItems,
}: Props<T>): JSX.Element {
  const { t } = useTranslation();

  const [isOptionListShown, setIsOptionListShown] = useState<boolean>(false);
  const [isFilterDirty, setIsFilterDirty] = useState<boolean>(false);

  const filterRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const options = useMemo(() => {
    if (lazy) {
      return maxLazyItems ? _options.slice(0, maxLazyItems) : _options;
    }

    if (!filterValue) {
      return _options.slice(0, maxNonLazyItems);
    }

    const labelRegex = new RegExp(`^${escapeRegex(filterValue)}`, 'i');

    return _options
      .filter((o) => !!o.label.match(labelRegex))
      .slice(0, maxNonLazyItems);
  }, [_options, filterValue, lazy, maxLazyItems, maxNonLazyItems]);

  useEffect(() => {
    if (isOptionListShown) {
      return;
    }

    if (forceSelection && isFilterDirty) {
      onFilterChange('');
      onSelectionChange(null);
    }
  }, [
    forceSelection,
    isFilterDirty,
    isOptionListShown,
    onFilterChange,
    onSelectionChange,
  ]);

  const listBoxClassnames = classNames(
    styles.listBox,
    'p-shadow-3',
    '__jest-listbox__',
    optionsClassName,
    {
      [styles.shown]: isOptionListShown,
    }
  );

  const filterRefRect = filterRef.current?.getBoundingClientRect();

  const listBoxStyle = {
    ...(filterRefRect
      ? {
          // top = right below input field + respect potential page scroll
          top: filterRefRect.top + filterRefRect.height + window.pageYOffset,
          left: filterRefRect.left,
          width: filterRefRect.width,
        }
      : {}),
    ...(typeof maxWidth === 'number' ? { maxWidth } : {}),
    ...(maxHeight && isOptionListShown ? { maxHeight } : {}),
  };

  function handleContainerBlur(e: FocusEvent) {
    if (
      !containerRef.current?.contains(e.relatedTarget as any) &&
      !(listBoxRef.current?.element as HTMLDivElement | undefined)?.contains(
        e.relatedTarget as any
      )
    ) {
      setIsOptionListShown(false);
    }
  }

  useEffect(() => {
    if (!value) {
      return;
    }

    setIsFilterDirty(false);
  }, [value]);

  function onClearBtnClick() {
    onFilterChange('');
    onSelectionChange(null);
  }

  const selectedOption = value ? findOption(value, options) : undefined;
  const inputTextValue =
    value && valueTemplate && selectedOption
      ? valueTemplate(selectedOption)
      : filterValue;

  return (
    <div
      ref={containerRef}
      onBlur={handleContainerBlur}
      className="p-fluid"
      style={maxWidth ? { maxWidth } : {}}
    >
      {label && (
        <label htmlFor={id} className="p-d-block">
          {label}
        </label>
      )}
      <span className="p-input-icon-right">
        {isLoading ? <i className="pi pi-spin pi-spinner" /> : null}

        <div className="p-inputgroup">
          <InputText
            id={id}
            name={id || name}
            ref={filterRef as any}
            value={inputTextValue}
            disabled={disabled || (!isOptionListShown && isLoading)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setIsFilterDirty(true);
              onFilterChange(e.target.value ?? '');
              onSelectionChange(null);
            }}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'ArrowDown' && options.length > 0) {
                e.preventDefault();

                listBoxRef.current?.element
                  ?.querySelector(`[aria-label="${options[0].label}"]`)
                  ?.focus();
              }
            }}
            placeholder={placeholder}
            onFocus={() => setIsOptionListShown(true)}
            autoComplete="off"
            data-testid="filter"
            data-cy={filterDataCy}
          />

          {showClear && value && (
            <Button
              type="button"
              icon="pi pi-times"
              onClick={onClearBtnClick}
              className={classNames(
                styles.clearBtn,
                'p-button-outlined p-button-plain'
              )}
            />
          )}
        </div>
      </span>

      {(!hideEmptyOptions || options.length > 0) &&
        ReactDOM.createPortal(
          <ListBox
            ref={listBoxRef}
            value={value}
            options={
              options.length > 0
                ? options
                : ([
                    { label: t('No results found'), value: null },
                  ] as typeof options)
            }
            itemTemplate={options.length > 0 ? itemTemplate : emptyItemTemplate}
            onChange={(e) => {
              if (!e.value && disableUnselect) {
                setIsOptionListShown(false);
                return;
              }

              onFilterChange(findOption(e.value, options)?.label ?? '');
              onSelectionChange(e.value ?? null);

              setIsFilterDirty(false);
              setIsOptionListShown(false);
            }}
            className={listBoxClassnames}
            style={listBoxStyle}
          />,
          document.body
        )}
    </div>
  );
}

export default AutoCompleteInput;
